import Vue from 'vue';
import VueRouter from 'vue-router';
import {authModule} from '@/store/modules';
import {Route} from '@/enums';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: Route.Dashboard,
    component: () =>
      import(/* webpackChunkName: 'dashboard' */ '@/views/Dashboard.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/auth',
    name: Route.Auth,
    component: () =>
      import(/* webpackChunkName: 'auth' */ '@/views/Authentication.vue'),
  },
  {
    path: '/clients',
    name: Route.Clients,
    component: () =>
      import(/* webpackChunkName: 'invoice-view' */ '@/views/Clients.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/invoices',
    name: Route.Invoices,
    component: () =>
      import(/* webpackChunkName: 'invoices' */ '@/views/Invoices.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/invoices/:invoiceId',
    name: Route.InvoiceView,
    component: () =>
      import(
        /* webpackChunkName: 'invoice-view' */ '@/views/invoices/InvoiceView.vue'
      ),
    meta: {requiresAuth: true},
  },
  {
    path: '/invoices/:invoiceId/edit',
    name: Route.InvoiceView,
    component: () =>
      import(
        /* webpackChunkName: 'invoice-edit' */ '@/views/invoices/InvoiceEdit.vue'
      ),
    meta: {requiresAuth: true},
  },
  {
    path: '/projects',
    name: Route.Projects,
    component: () =>
      import(/* webpackChunkName: 'projects' */ '@/views/Projects.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/reports/earnings',
    name: Route.ReportEarnings,
    component: () =>
      import(
        /* webpackChunkName: 'report-summary-year' */ '@/views/reports/Earnings.vue'
      ),
    meta: {requiresAuth: true},
  },
  {
    path: '/reports/hours-per-year',
    name: Route.ReportHoursPerYear,
    component: () =>
      import(
        /* webpackChunkName: 'report-summary-year' */ '@/views/reports/HoursPerYear.vue'
      ),
    meta: {requiresAuth: true},
  },
  {
    path: '/reports/summary/year',
    name: Route.ReportSummaryYear,
    component: () =>
      import(
        /* webpackChunkName: 'report-summary-year' */ '@/views/reports/SummaryYear.vue'
      ),
    meta: {requiresAuth: true},
  },
  {
    path: '/time-entries',
    name: Route.TimeEntries,
    component: () =>
      import(/* webpackChunkName: 'time-entries' */ '@/views/TimeEntries.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/timers',
    name: 'timers',
    component: () =>
      import(/* webpackChunkName: 'timer' */ '@/views/Timers.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {requiresAuth: true},
    component: () =>
      import(/* webpackChunkName: 'contact' */ '@/views/Contact.vue'),
  },
  {
    path: '*',
    name: Route.NotFound,
    meta: {requiresAuth: false},
    component: () =>
      import(/* webpackChunkName: 'not-found' */ '@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const routeInfo = {
    to: to.name,
    from: from.name,
    toRouteRequiresAuth: to.matched.some(record => record.meta.auth),
    isUserAuthenticated: authModule.isAuthenticated,
  };
  console.debug('router.beforeEach', routeInfo);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!authModule.isAuthenticated) {
      const redirect =
        to.query.redirect === undefined
          ? encodeURIComponent(to.fullPath)
          : to.query.redirect;
      next({
        name: 'auth',
        query: {redirect: redirect},
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
