var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:_vm.$route.name === _vm.route.Auth ? 'app-bg-image' : ''},[_c('v-app-bar',{attrs:{"app":"","color":_vm.$route.name === _vm.route.Auth ? 'rgba(68, 68, 68, 0.7)' : '#444444',"dark":"","shrink-on-scroll":_vm.$route.name !== _vm.route.Auth,"prominent":_vm.$route.name !== _vm.route.Auth,"position":"top center","src":require("./assets/bg2.jpg")}},[(_vm.authenticated)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-img',{staticClass:"shrink mx-auto justify-center align-self-center",attrs:{"id":"logo","src":require("./assets/crackle-cat-inc-logo-v1-dark.svg"),"data-src":"./assets/john-clark-software-consulting-work-logo.png","alt":"Crackle Cat Inc.","contain":"","transition":"scale-transition"}}),(_vm.authenticated)?_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.loading,"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1709056482)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.signOut()}}},[_c('v-list-item-title',[_vm._v("Sign out")])],1)],1)],1):_vm._e()],1),(_vm.authenticated)?_c('v-navigation-drawer',{attrs:{"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},_vm._l((_vm.role === 'staff'
          ? _vm.staffItems
          : _vm.role === 'time-entry'
          ? _vm.timeEntryItems
          : _vm.clientItems),function(item){return _c('div',{key:item.title},[(!item.items)?_c('v-list-item',{attrs:{"to":item.to,"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1):_vm._e(),(item.items)?_c('v-list-group',{attrs:{"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.items),function(subItem){return _c('v-list-item',{key:subItem.title,attrs:{"to":subItem.to,"link":""}},[_c('v-list-item-icon'),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(subItem.title)}})],1)],1)}),1):_vm._e()],1)}),0)],1):_vm._e(),_c('v-main',[_c('router-view')],1),_c('v-footer',{attrs:{"color":_vm.$route.name === _vm.route.Auth
        ? 'rgba(68, 68, 68, 0.7)'
        : 'rgba(200, 200, 200, 0.5)'}},[_c('v-container',{staticClass:"text-center"},[_vm._v(" Copyright © "+_vm._s(new Date().getFullYear())+" Crackle Cat Inc. All rights reserved."),_c('br'),_vm._v(" Version: "+_vm._s(_vm.version)+" / "+_vm._s(_vm.apiVersion)+" ")])],1),(_vm.environment === 'development')?_c('div',{staticStyle:{"position":"fixed","bottom":"0","left":"0","color":"white","background-color":"black","padding":"5px","font-size":"16px","font-weight":"bold","z-index":"100000"}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.name)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }