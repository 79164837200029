import {Module} from 'vuex-class-modules';
import store from '@/store';
import {invoiceApi as api} from '@/api';
import {
  InvoiceAttributes as Attributes,
  InvoiceCreate as Create,
  InvoiceResource,
  InvoiceResource as Resource,
} from '@/interfaces';
import {Currency, InvoiceType, ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Invoice,
    attributes: {
      id: 0,
      billingAddress: '',
      client: {
        id: 0,
        name: '',
      },
      clientId: 0,
      comments: '',
      confirmed: false,
      created: new Date(),
      currency: Currency.CAD,
      date: new Date(),
      discount: 0,
      dueDate: new Date(),
      fromAddress: '',
      lines: [],
      modified: new Date(),
      subtotal: 0,
      tax: 0,
      terms: 'NET 30',
      total: 0,
      type: InvoiceType.Service,
      user: {
        id: 0,
        firstName: '',
        lastName: '',
      },
    },
  };
};

@Module
class InvoiceModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  protected cast(item: InvoiceResource) {
    item.attributes.created = new Date(String(item.attributes.created));
    item.attributes.modified = new Date(String(item.attributes.modified));
    return item;
  }
}

export const invoiceModule = new InvoiceModule(
  {
    store,
    name: ResourceType.Invoice,
  },
  () => api,
  defaultItem
);
