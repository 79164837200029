import numerals from 'numeral';
import {format as formatDate, formatDistanceToNow, parse} from 'date-fns';

export const centsToDollars = (value: number) =>
  numerals(value / 100).format('0,0.00');

export const centsToDollarsRounded = (value: number) =>
  numerals(value / 100).format('0,0');

export const d60 = (value: number) => value / 60;

export const d100 = (value: number) => value / 100;

export const minutesToHoursRounded = (value: number) =>
  numerals(value / 60).format('0,0');

export const minutesToHours = (value: number) =>
  numerals(value / 60).format('0,0.00');

export const money = (value: number) => numerals(value).format('0,0.00');

export const numberFormat = (value: number) => numerals(value).format('0,0.00');

export const n2br = (value: string): string => {
  if (!value) {
    return '';
  }
  return value.replace(/\n/g, '<br/>');
};

export const initials = (value: string) => {
  const initials = value.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const dateFormatDistanceToNow = (value: string) =>
  formatDistanceToNow(new Date(value));

export const dateFormat = (value: Date, format?: string) =>
  formatDate(value, format === undefined ? 'PPPPpppp' : format);

/**
 * Expects a date in 2021-04-10 format and converts it to
 * @param date
 */
export const shortDate = (date: string): string => {
  return formatDate(parse(date, 'yyyy-MM-dd', new Date()), 'MMM d, yyyy');
};

export const fullDateTime = (date: string): string => {
  return formatDate(
    parse(date, 'yyyy-MM-dd HH:mm:ss', new Date()),
    'yyyy-MM-dd HH:mm:ss.SSS'
  );
};

export const longDate = (date: string): string => {
  return formatDate(
    parse(date, 'yyyy-MM-dd', new Date()),
    'EEEE, MMMM, do yyyy'
  );
};

export const msToTimeDisplay = (ms: number): string => {
  const s = ms / 1000;
  const hoursNum = Math.floor(s / 3600);
  const minutesNum = Math.floor((s % 3600) / 60);
  const secondsNum = Math.floor(s % 60);

  const hours = hoursNum < 10 ? `0${hoursNum}` : String(hoursNum);
  const minutes = minutesNum < 10 ? `0${minutesNum}` : String(minutesNum);
  const seconds = secondsNum < 10 ? `0${secondsNum}` : String(secondsNum);

  return `${hours}:${minutes}:${seconds}`;
};

export const timeDisplayToMs = (timeDisplay: string): number => {
  if (timeDisplay.length !== 8) {
    throw Error(
      'timeDisplay must be 8 characters in length and in the HH:MM:SS format'
    );
  }
  const hours = parseInt(timeDisplay.substr(0, 2), 10);
  const minutes = parseInt(timeDisplay.substr(3, 2), 10);
  const seconds = parseInt(timeDisplay.substr(6, 2), 10);

  return (hours * 3600 + minutes * 60 + seconds) * 1000;
};

export const timeAgo = (date: Date) => formatDistanceToNow(date);
