import {Action, Module, Mutation} from 'vuex-class-modules';
import store from '@/store';
import {timeEntryApi as api} from '@/api';
import {
  TimeEntryAttributes as Attributes,
  TimeEntryCreate as Create,
  TimeEntryResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';
import {format, subMonths} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.TimeEntry,
    attributes: {
      id: 0,
      billed: 0,
      client: {id: 0, name: ''},
      clientId: 0,
      created: new Date(),
      date: new Date(),
      description: '',
      duration: 0,
      durationExpense: 0,
      durationHours: '',
      modified: new Date(),
      project: {id: 0, name: ''},
      projectId: 0,
      task: {id: 0, name: ''},
      taskId: 0,
      user: {id: 0, firstName: '', lastName: ''},
      userId: 0,
    },
  };
};

@Module
class TimeEntryModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  private filters: Record<string, string | number | null> = {
    projectId: -1,
    clientId: -1,
    taskId: -1,
    search: '',
    groupBy: null,
    dateEnd: format(new Date(), 'yyyy-MM-dd'),
    dateStart: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    billingStatus: -1,
  };

  get getFilters(): Record<string, string | number | null> {
    return this.filters;
  }

  @Action
  initLocalStorage(): void {
    const filters = localStorage.getItem('timeEntries.filters');
    if (filters !== null) {
      console.info('Filters found in local storage, use them...', filters);
      this.updateFilters(JSON.parse(filters));
    }
  }

  @Action
  setFilters(): void {
    this.updateFilters(this.getFilters);
  }

  @Mutation
  updateFilters(filters: Record<string, string | number | null>): void {
    localStorage.setItem('timeEntries.filters', JSON.stringify(filters));
    this.filters = filters;
  }
}

export const timeEntryModule = new TimeEntryModule(
  {
    store,
    name: ResourceType.TimeEntry,
  },
  () => api,
  defaultItem
);
