import {HttpClient} from '@/HttpClient';
import {ResourceUrl} from '@/enums';

export class IndexApi extends HttpClient {
  public constructor() {
    super(ResourceUrl.Index);
  }

  public async getVersion(): Promise<string> {
    const response = await this.instance.get('');
    return response.data.attributes.version;
  }
}
