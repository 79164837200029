export enum ResourceUrl {
  Action = 'actions',
  Auth = 'auth',
  Client = 'clients',
  Index = '',
  Invoice = 'invoices',
  Project = 'projects',
  Report = 'reports',
  Task = 'tasks',
  TimeEntry = 'time-entries',
  Timer = 'timers',
  User = 'users',
}
