import {AuthApi} from '@/api/AuthApi';
import {ClientApi} from '@/api/ClientApi';
import {IndexApi} from '@/api/IndexApi';
import {InvoiceApi} from '@/api/InvoiceApi';
import {ProjectApi} from '@/api/ProjectApi';
import {ReportApi} from '@/api/ReportApi';
import {TaskApi} from '@/api/TaskApi';
import {TimeEntryApi} from '@/api/TimeEntryApi';
import {TimerApi} from '@/api/TimerApi';
import {UserApi} from '@/api/UserApi';
import {ActionApi} from '@/api/ActionApi';

export const actionApi = new ActionApi();
export const authApi = new AuthApi();
export const clientApi = new ClientApi();
export const indexApi = new IndexApi();
export const invoiceApi = new InvoiceApi();
export const projectApi = new ProjectApi();
export const reportApi = new ReportApi();
export const taskApi = new TaskApi();
export const timeEntryApi = new TimeEntryApi();
export const timerApi = new TimerApi();
export const userApi = new UserApi();
