import {TimerCreate, TimerResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceUrl} from '@/enums';

export class TimerApi extends CrudApiAbstract<
  TimerResource,
  TimerCreate
> {
  public constructor() {
    super(ResourceUrl.Timer);
  }
}
