import {InvoiceCreate, InvoiceResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceUrl} from '@/enums';
import {AxiosRequestConfig, AxiosResponse} from 'axios';

export class InvoiceApi extends CrudApiAbstract<
  InvoiceResource,
  InvoiceCreate
> {
  public constructor() {
    super(ResourceUrl.Invoice);
  }

  public async getRaw(
    id: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return await this.instance.get(`/${id}`, config);
  }
}
