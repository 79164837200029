import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import store from '@/store';
import {authApi as api} from '@/api';
import {AuthAttributes, AuthCreate, AuthResource} from '@/interfaces';
import {sleep} from '@/utility';
import {ResourceType, Role} from '@/enums';

@Module
class AuthModule extends VuexModule {
  authResource: AuthResource | null = null;
  authenticating = false;
  loading = false;
  signingOut = false;

  get auth(): AuthResource | null {
    return this.authResource;
  }

  get clientId(): number | null {
    return this.authResource ? this.authResource.attributes.clientId : null;
  }

  get firstName(): string | null {
    return this.authResource ? this.authResource.attributes.firstName : null;
  }

  get role(): Role | null {
    return this.authResource ? this.authResource.attributes.role : null;
  }

  get isAuthenticated(): boolean {
    return this.authResource === null
      ? false
      : this.authResource.attributes.authenticated;
  }

  get isAuthenticating() {
    return this.authenticating;
  }

  get isLoading() {
    return this.loading;
  }

  get isSigningOut() {
    return this.signingOut;
  }

  @Mutation
  setAuth(auth: AuthResource) {
    this.authResource = auth;
  }

  @Mutation
  setAuthenticating(isAuthenticating: boolean) {
    this.authenticating = isAuthenticating;
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.loading = isLoading;
  }

  @Mutation
  setSigningOut(isSigningOut: boolean) {
    this.signingOut = isSigningOut;
  }

  @Action
  async authenticate(authCreate: AuthCreate) {
    this.setAuthenticating(true);
    const a = await api.post(authCreate);
    console.log('a', a);
    this.setAuth(a);
    this.setAuthenticating(false);
    return this.auth;
  }

  @Action
  async signOut() {
    this.setSigningOut(true);
    this.setAuth(await api.delete('1'));
    this.setSigningOut(false);
  }

  @Action
  async checkAuth() {
    this.setLoading(true);

    if (process.env.NODE_ENV === 'development') {
      await sleep();
    }

    // get the API auth status
    this.setAuth(await api.get('1'));
    console.info('Is user authenticated:', this.isAuthenticated);

    // does user have API auth?
    this.setLoading(false);
  }

  refreshAuth() {
    // check auth every minute
    setInterval(async () => {
      console.log('Periodic auth check', this.isAuthenticated);

      await api.get('1');
    }, 60000);
  }
}

export const authModule = new AuthModule({store, name: ResourceType.Auth});
