import {Module} from 'vuex-class-modules';
import store from '@/store';
import {reportApi as api} from '@/api';
import {
  ReportAttributes as Attributes,
  ReportCreate as Create,
  ReportResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Report,
    attributes: {
      id: 0,
      clientStats: [
        {
          billingCycleCurrent: {
            start: new Date(),
            end: new Date(),
            name: '',
          },
          billingCycleDaysLeft: 0,
          billingCycleTotalDays: 0,
          client: {
            id: 0,
            name: '',
            rate: 0,
            discount: 0,
          },
          projectCount: 0,
          timeEntryCount: 0,
          unbilledMinutesPerDayInBillingCycle: 0,
          unbilledEarningsPerDayInBillingCycle: 0,
          unbilledMinutes: 0,
          unbilledSubtotal: 0,
          unbilledDiscount: 0,
          unbilledTotal: 0,
        },
      ],
      unbilledTotal: 0,
      unbilledMinutes: 0,
      unbilledMinutesThisMonth: 0,
      unbilledTotalPerDayThisMonth: 0,
      unbilledTotalPerBusinessDayThisMonth: 0,
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class ReportModule extends CrudModuleAbstract<Resource, Attributes, Create> {}

export const reportModule = new ReportModule(
  {
    store,
    name: ResourceType.Report,
  },
  () => api,
  defaultItem
);
