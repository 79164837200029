import {TimeEntryCreate, TimeEntryResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceUrl} from '@/enums';

export class TimeEntryApi extends CrudApiAbstract<
  TimeEntryResource,
  TimeEntryCreate
> {
  public constructor() {
    super(ResourceUrl.TimeEntry);
  }
}
