import {Module} from 'vuex-class-modules';
import store from '@/store';
import {projectApi as api} from '@/api';
import {
  ProjectAttributes as Attributes,
  ProjectCreate as Create,
  ProjectResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Project,
    attributes: {
      id: 0,
      archived: false,
      clientId: 0,
      description: '',
      name: '',
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class ProjectModule extends CrudModuleAbstract<Resource, Attributes, Create> {}

export const projectModule = new ProjectModule(
  {
    store,
    name: ResourceType.Project,
  },
  () => api,
  defaultItem
);
