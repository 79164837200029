import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import './filters';
import {timeEntryModule} from '@/store/modules';
import Clipboard from 'v-clipboard';

Vue.config.productionTip = false;

Vue.use(Clipboard);

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    timeEntryModule.initLocalStorage();
  },
  render: h => h(App),
}).$mount('#app');
