export enum Route {
  Auth = 'auth',
  Contact = 'contact',
  Clients = 'clients',
  Dashboard = 'dashboard',
  Invoices = 'invoices',
  InvoiceEdit = 'invoice-edit',
  InvoiceView = 'invoice-view',
  NotFound = 'not-found',
  Projects = 'projects',
  ReportEarnings = 'report-earnings',
  ReportHoursPerYear = 'report-hours-per-year',
  ReportSummaryYear = 'report-summary-year',
  TimeEntries = 'time-entries',
  Timers = 'timers',
}
