export enum ResourceType {
  Action = 'action',
  Auth = 'auth',
  Client = 'client',
  Invoice = 'invoice',
  Project = 'project',
  Report = 'report',
  Task = 'task',
  Timer = 'timer',
  TimeEntry = 'time-entry',
  User = 'user',
}
