




























































































































import Vue from 'vue';
import {
  mdiGroup,
  mdiViewDashboard,
  mdiFileDocumentMultiple,
  mdiClockOutline,
  mdiTimer,
  mdiFileChart,
  mdiFileCad,
  mdiCardAccountDetails,
  mdiChartBellCurve,
  mdiChartAreaspline,
  mdiChartBar,
} from '@mdi/js';
import Component from 'vue-class-component';
import {authModule} from '@/store/modules';
import {Role, Route} from '@/enums';
import {indexApi} from '@/api';

@Component
export default class App extends Vue {
  private apiVersion: string | null = null;
  drawer = false;
  environment = process.env.NODE_ENV;
  version = process.env.VERSION;
  route = Route;
  staffItems = [
    {
      to: '/',
      icon: mdiViewDashboard,
      title: 'Dashboard',
    },
    {
      to: '/time-entries',
      icon: mdiClockOutline,
      title: 'Time Entries',
    },
    {
      to: '/timers',
      icon: mdiTimer,
      title: 'Timers',
    },
    {
      to: '/invoices',
      icon: mdiFileDocumentMultiple,
      title: 'Invoices',
    },
    {
      to: '/projects',
      icon: mdiFileCad,
      title: 'Projects',
    },
    {
      to: '/clients',
      icon: mdiGroup,
      title: 'Clients',
    },
    {
      icon: mdiFileChart,
      title: 'Reports',
      items: [
        {
          to: '/reports/earnings',
          title: 'Earnings',
          icon: mdiChartAreaspline,
        },
        {
          to: '/reports/hours-per-year',
          title: 'Hours per year',
          icon: mdiChartBar,
        },
        {
          to: '/reports/summary/year',
          title: 'Summary by Year',
          icon: mdiChartBellCurve,
        },
      ],
    },
    {
      to: '/contact',
      icon: mdiCardAccountDetails,
      title: 'Contact',
    },
  ];
  timeEntryItems = [
    {
      to: '/',
      icon: mdiViewDashboard,
      title: 'Dashboard',
    },
    {
      to: '/time-entries',
      icon: mdiClockOutline,
      title: 'Time Entries',
    },
    {
      to: '/timers',
      icon: mdiTimer,
      title: 'Timers',
    },
    // {
    //   to: '/invoices',
    //   icon: mdiFileDocumentMultiple,
    //   title: 'Invoices',
    // },
    // {
    //   to: '/projects',
    //   icon: mdiFileCad,
    //   title: 'Projects',
    // },
    // {
    //   to: '/clients',
    //   icon: mdiGroup,
    //   title: 'Clients',
    // },
    // {
    //   icon: mdiFileChart,
    //   title: 'Reports',
    //   items: [
    //     {
    //       to: '/reports/earnings',
    //       title: 'Earnings',
    //       icon: mdiChartAreaspline,
    //     },
    //     {
    //       to: '/reports/hours-per-year',
    //       title: 'Hours per year',
    //       icon: mdiChartBar,
    //     },
    //     {
    //       to: '/reports/summary/year',
    //       title: 'Summary by Year',
    //       icon: mdiChartBellCurve,
    //     },
    //   ],
    // },
    {
      to: '/contact',
      icon: mdiCardAccountDetails,
      title: 'Contact',
    },
  ];
  clientItems = [
    {
      to: '/',
      icon: mdiViewDashboard,
      title: 'Dashboard',
    },
    {
      to: '/time-entries',
      icon: mdiClockOutline,
      title: 'Time Entries',
    },
    {
      to: '/invoices',
      icon: mdiFileDocumentMultiple,
      title: 'Invoices',
    },
    {
      to: '/projects',
      icon: mdiFileCad,
      title: 'Projects',
    },
    // {
    //   icon: mdiFileChart,
    //   title: 'Reports',
    //   items: [
    //     {
    //       to: '/reports/hours-per-year',
    //       title: 'Hours per year',
    //       icon: mdiFileChart
    //     }
    //   ],
    // },
    {
      to: '/contact',
      icon: mdiCardAccountDetails,
      title: 'Contact',
    },
  ];

  get authenticated(): boolean {
    return authModule.isAuthenticated;
  }

  get loading(): boolean {
    return authModule.isLoading;
  }

  get role(): Role | null {
    return authModule.auth ? authModule.auth.attributes.role : null;
  }

  async created(): Promise<void> {
    console.info('App Version:', process.env.VERSION);
    console.info('App Environment:', process.env.NODE_ENV);
    this.apiVersion = await indexApi.getVersion();
    console.info('API Version:', this.apiVersion);
  }

  async signOut(): Promise<void> {
    await authModule.signOut();
    await this.$router.push({name: Route.Auth});
  }
}
