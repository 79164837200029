import {ProjectCreate, ProjectResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceUrl} from '@/enums';

export class ProjectApi extends CrudApiAbstract<
  ProjectResource,
  ProjectCreate
> {
  public constructor() {
    super(ResourceUrl.Project);
  }
}
