import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {JsonApiFetchingParams} from '@/interfaces';
import router from './router';
import {authApi} from '@/api';

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  /**
   * HttpClient Constructor
   *
   * @param resource Should be ResourceType but in most cases plural
   */
  public constructor(resource: string) {
    this.instance = axios.create({
      baseURL: `${process.env.VUE_APP_API_BASE_URL}/${resource}`,
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
      },
      withCredentials: true,
    });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
    this.instance.interceptors.response.use(
      function (successRes) {
        return successRes;
      },
      async error => {
        if (error.response.status === 401) {
          await authApi.delete('1');

          await router.push({name: 'auth'});
        }
        return Promise.reject(error);
      }
    );
  };

  private handleResponse = ({data}: AxiosResponse) => data;

  protected handleError = (error: Error) => Promise.reject(error);
}
