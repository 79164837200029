import {ClientCreate, ClientResource} from '@/interfaces';
import {CrudApiAbstract} from '@/api/CrudApiAbstract';
import {ResourceUrl} from '@/enums';

export class ClientApi extends CrudApiAbstract<
  ClientResource,
  ClientCreate
> {
  public constructor() {
    super(ResourceUrl.Client);
  }
}
