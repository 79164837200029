import {Module} from 'vuex-class-modules';
import store from '@/store';
import {timerApi as api} from '@/api';
import {
  TimerAttributes as Attributes,
  TimerCreate as Create,
  TimerResource,
  TimerResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

import {msToTimeDisplay} from '@/filters';
import {format} from 'date-fns';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.Timer,
    attributes: {
      id: 0,
      billable: true,
      clientId: 1,
      description: '',
      durationExpense: 0,
      date: format(new Date(), 'yyyy-MM-dd'),
      log: [],
      projectId: 1,
      running: false,
      started: new Date(),
      stopped: new Date(),
      time: 0,
      timeDisplay: '00:00:00',
      taskId: 2259,
      userId: 1,
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class TimerModule extends CrudModuleAbstract<Resource, Attributes, Create> {
  cast(item: TimerResource) {
    // Note: do not cast date property
    item.attributes.started = new Date(String(item.attributes.started));
    item.attributes.stopped = new Date(String(item.attributes.stopped));
    item.attributes.created = new Date(String(item.attributes.created));
    item.attributes.modified = new Date(String(item.attributes.modified));
    item.attributes.timeDisplay = msToTimeDisplay(item.attributes.time);
    return item;
  }
}

export const timerModule = new TimerModule(
  {
    store,
    name: ResourceType.Timer,
  },
  () => api,
  defaultItem
);
